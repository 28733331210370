// import React from "react";
// import { useState } from "react";
// import { useEffect } from "react";
// import { useRef } from "react";
// import { useHistory } from "react-router-dom";
// import { FaRegUserCircle, FaTimes, FaSearch } from "react-icons/fa";
// import { CiSearch } from "react-icons/ci";
// import { FileCopy as FileCopyIcon } from "@mui/icons-material";

// import CryptoJS from "crypto-js";

// import CircularProgress from "@mui/material/CircularProgress";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";

// import useHttp from "../../Hooks/use-http";
// import DriverBooking from "./PrivateDriverBooking";
// import Loading from "../../Loading/Loading";
// import Message from "../../Modal/Message";
// import "./LiveTracking.css";
// import startPoint from "../../Assets/dropIcon.png";
// import endPoint from "../../Assets/pickIcon.png";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { IconButton, TextField, Tooltip } from "@mui/material";

// let prev_driverEmail = "";
// let flightPlanCoordinates = [];
// let emailFlag = true;
// let driverFlag = true;
// let trip_interval = "";
// let pathInterval = "";
// let myFlag = 1;
// let map;
// let prev_driverId = "";
// let tripID;
// let flightPath1;
// let flightPath2;
// let marker;
// let startPointMarker;
// let endPointMarker;
// var numDeltas = 100;
// var delay = 20; //milliseconds
// var i = 0;
// var deltaLat;
// var deltaLng;

// let drawLineFlag = false;
// let journeyStart = 0;
// let onTripDriverName = "";
// let riderDetails = [];
// let onlineDriversMarker = [];
// const LiveTracking = (props) => {
//   const [bookedDriver, setBookedDriver] = useState(false);
//   const [onTripDriverEmail, setOnTripDriverEmail] = useState(null);
//   const [tripRequestStatus, setTripRequestStatus] = useState(false);
//   const [isLoadingRoute, setIsLoadingRoute] = useState(false);
//   const [isTripEnded, setIsTripEnded] = useState(false);
//   const [driverFilterType, setDriverFilterType] = useState("All");
//   const [filteredDriverData, setFilteredDriverData] = useState([]);
//   const [servicesTabbarValue, setServicesTabbarValue] = useState(0);
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [copiedItemId, setCopiedItemId] = useState(null);
//   const [trpID, setTrpID] = useState(null);

//   const [isSearchActive, setIsSearchActive] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");

//   const searchInputRef = useRef();
//   const history = useHistory();

//   useEffect(() => {
//     // debugger;
//     if (props?.driverData?.length > 0 && !props.isLoading) {
//       setFilteredDriverData(props.driverData);
//     }
//   }, [props.driverData, props.isLoading]);

//   function transition() {
//     i = 0;
//     deltaLat =
//       (flightPlanCoordinates[flightPlanCoordinates.length - 1].lat -
//         flightPlanCoordinates[flightPlanCoordinates.length - 2].lat) /
//       numDeltas;
//     deltaLng =
//       (flightPlanCoordinates[flightPlanCoordinates.length - 1].lng -
//         flightPlanCoordinates[flightPlanCoordinates.length - 2].lng) /
//       numDeltas;
//     moveMarker();
//   }

//   function moveMarker() {
//     flightPlanCoordinates[flightPlanCoordinates.length - 2].lat += deltaLat;
//     flightPlanCoordinates[flightPlanCoordinates.length - 2].lng += deltaLng;
//     var latlng = new window.google.maps.LatLng(
//       flightPlanCoordinates[flightPlanCoordinates.length - 2].lat,
//       flightPlanCoordinates[flightPlanCoordinates.length - 2].lng
//     );
//     marker.setPosition(latlng);
//     if (i != numDeltas) {
//       i++;
//       setTimeout(moveMarker, delay);
//     }
//   }

//   useEffect(() => {
//     prev_driverEmail = "";
//     riderDetails = [];
//   }, []);

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src =
//       "https://maps.googleapis.com/maps/api/js?key=AIzaSyDHdkmGjsfNqasFs6m9CooShFZsqWHcdUs&callback=myInitMap&libraries=places&v=weekly";
//     script.async = true;
//     document.body.appendChild(script);
//   }, [onTripDriverEmail, driverFilterType]);

//   if (
//     (prev_driverEmail && prev_driverEmail !== onTripDriverEmail && myFlag) ||
//     (prev_driverEmail == null && onTripDriverEmail == null)
//   ) {
//     // console.log("here", trip_interval);
//     clearIntervalApiCall();
//     clearIntervalFligthPath();
//     // intervalApiCall();
//     setTimeout(() => {
//       flightPathInterval();
//     });
//     myFlag = 0;
//   } else myFlag = 1;

//   const authenticateUser = (data) => {
//     if (!onTripDriverEmail) {
//       if (data) {
//         try {
//           let driverDetails = JSON.parse(data);
//           for (let i = 0; i < onlineDriversMarker?.length; i++) {
//             onlineDriversMarker[i]?.setMap(null);
//           }
//           onlineDriversMarker = [];
//           const infoWindow = new window.google.maps.InfoWindow();
//           for (let i = 0; i < driverDetails.length; i++) {
//             if (
//               driverDetails[i].Latitude > 0 &&
//               (driverFilterType === "Online"
//                 ? !driverDetails[i].TripID
//                 : driverFilterType === "On Trip"
//                 ? driverDetails[i].TripID
//                 : 1)
//             ) {
//               onlineDriversMarker[i] = new window.google.maps.Marker({
//                 position: {
//                   lat: driverDetails[i]?.Latitude,
//                   lng: driverDetails[i]?.Longitude,
//                 },
//                 map,
//                 icon: {
//                   url: carIcon,
//                   scaledSize: new window.google.maps.Size(34, 34),
//                   anchor: new window.google.maps.Point(17, 17),
//                 },
//                 myTitle: `${driverDetails[i].FullName}`,
//               });
//               onlineDriversMarker[i].addListener("click", () => {
//                 infoWindow.close();
//                 infoWindow.setContent(onlineDriversMarker[i].myTitle);
//                 infoWindow.open(
//                   onlineDriversMarker[i].getMap(),
//                   onlineDriversMarker[i]
//                 );
//               });
//             }
//           }
//           const markerUrl =
//             carIcon;
//           setTimeout(() => {
//             for (let i = 0; i < onlineDriversMarker.length; i++) {
//               let markerSrc = document.querySelectorAll(
//                 `[src = "${markerUrl}"]`
//               );
//               if (onlineDriversMarker[i] && markerSrc[i])
//                 markerSrc[
//                   i
//                 ].style.transform = `rotate(${driverDetails[i].Bearing}deg)`;
//             }
//           }, 2000);
//         } catch (error) {
//           console.error("Error parsing driver details JSON:", error);
//         }
//       }
//     } else {
//       if (data.Livetripdetails) {
//         // console.log(onTripDriverEmail, data.Livetripdetails[0].EmailID);
//         if (!driverFlag) {
//           if (
//             flightPlanCoordinates[flightPlanCoordinates.length - 1]?.lat !==
//               data.Livetripdetails[0]?.Latitude &&
//             flightPlanCoordinates[flightPlanCoordinates.length - 1]?.lng !==
//               data.Livetripdetails[0]?.Longitude
//           )
//             flightPlanCoordinates.push({
//               lat: data.Livetripdetails[0]?.Latitude,
//               lng: data.Livetripdetails[0]?.Longitude,
//             });
//         } else {
//           flightPlanCoordinates = [];
//           for (let i = 0; i < data.Livetripdetails.length; i++) {
//             flightPlanCoordinates.push({
//               lat: data.Livetripdetails[i]?.Latitude,
//               lng: data.Livetripdetails[i]?.Longitude,
//             });
//           }
//           drawLineFlag = true;
//           driverFlag = false;
//         }
//         journeyStart = 1;

//         const markerUrl =
//           carIcon;
//         let markerSrc = document.querySelector(`[src = "${markerUrl}"]`);
//         if (marker && markerSrc)
//           markerSrc.style.transform = `rotate(${
//             data.Livetripdetails[data.Livetripdetails.length - 1].Bearing
//           }deg)`;

//         setIsTripEnded(false);
//       } else {
//         if (data?.LivetripStatus?.toLowerCase() === "ended") {
//           setIsTripEnded(true);
//           flightPlanCoordinates = [];
//         } else setIsTripEnded(false);
//       }

//       if (
//         !(
//           data?.LivetripStatus?.toLowerCase() === "ended" ||
//           data?.LivetripStatus?.toLowerCase() === "arrived"
//         )
//       ) {
//         if (startPointMarker) startPointMarker.setMap(null);
//         if (endPointMarker) endPointMarker.setMap(null);
//         riderDetails = [];
//         let tripDetails = {};
//         for (let i = 0; i < data.Livetrip?.length; i++) {
//           if (data.Livetrip[i].riderName) {
//             riderDetails.push(data.Livetrip[i]);
//           } else tripDetails = data.Livetrip[i];
//         }
//         // if(data.Livetrip)
//         startPointMarker = new window.google.maps.Marker({
//           position: tripDetails?.ActualPickupName
//             ? {
//                 lat: +tripDetails?.ActualPickupAddress.split(",")[0],
//                 lng: +tripDetails?.ActualPickupAddress.split(",")[1],
//               }
//             : {
//                 lat: tripDetails?.PickupLatitude,
//                 lng: tripDetails?.PickupLongitude,
//               },
//           map,
//           icon: startPoint,
//           myTitle: `${
//             tripDetails.ActualPickupName
//               ? tripDetails.ActualPickupName
//               : tripDetails?.PickupAddress?.split(",")[0]
//           }`,
//         });
//         endPointMarker = new window.google.maps.Marker({
//           position: {
//             lat: tripDetails?.DropoffLatitude,
//             lng: tripDetails?.DropoffLongitude,
//           },
//           map,
//           icon: endPoint,
//           myTitle: `${tripDetails?.DropoffAddress}`,
//         });
//         var bounds = new window.google.maps.LatLngBounds();
//         bounds.extend(
//           new window.google.maps.LatLng(
//             tripDetails?.PickupLatitude,
//             tripDetails?.PickupLongitude
//           )
//         );
//         bounds.extend(
//           new window.google.maps.LatLng(
//             tripDetails?.DropoffLatitude,
//             tripDetails?.DropoffLongitude
//           )
//         );
//         const infoWindow = new window.google.maps.InfoWindow();
//         startPointMarker.addListener("mouseover", () => {
//           infoWindow.close();
//           infoWindow.setContent(startPointMarker.myTitle);
//           infoWindow.open(startPointMarker.getMap(), startPointMarker);
//         });
//         endPointMarker.addListener("mouseover", () => {
//           infoWindow.close();
//           infoWindow.setContent(endPointMarker.myTitle);
//           infoWindow.open(endPointMarker.getMap(), endPointMarker);
//         });
//       }
//     }
//     setIsLoadingRoute(false);
//   };

//   const { isLoading, sendRequest } = useHttp();

//   useEffect(() => {
//     if (onTripDriverEmail) intervalApiCall();
//   }, [onTripDriverEmail]);

//   useEffect(() => {
//     if (!onTripDriverEmail) {
//       setIsLoadingRoute(true);
//       var secretkey = "f080786e3a348458a621e2fa4c267ad8";
//       var key = CryptoJS.enc.Utf8.parse(secretkey);
//       var iv = CryptoJS.enc.Utf8.parse("84jfkfndl3ybdfkf");

//       let data = `FORMID|JSONDATA|JSONDATA|{"FORMID":"GETDRIVERLOCATIONS_PD","VehicleTypes":{"CorporateID":"${localStorage.getItem(
//         "adminDepartmentID"
//       )}"},"Country":"INDIA","City":"AHMEDABAD","userId": "${localStorage.getItem(
//         "user"
//       )}"}`;
//       var cipherText = CryptoJS.AES.encrypt(data, key, {
//         iv: iv,
//         mode: CryptoJS.mode.CBC,
//         padding: CryptoJS.pad.Pkcs7,
//       }).toString();
//       sendRequest(
//         {
//           url: "/api/v1/DriverList/DriveronlineLocations",
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: {
//             cipherText: encodeURIComponent(cipherText),
//           },
//         },
//         authenticateUser
//       );
//     }
//   }, [onTripDriverEmail, driverFilterType]);

//   function intervalApiCall() {
//     flightPlanCoordinates = [];
//     if (prev_driverEmail == null) driverFlag = true;
//     else {
//       prev_driverEmail && prev_driverEmail === onTripDriverEmail
//         ? (driverFlag = false)
//         : (driverFlag = true);
//       prev_driverEmail = onTripDriverEmail;
//     }

//     sendRequest(
//       {
//         url: "/api/v1/LiveTrip/GetLiveTripDetails",
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: {
//           emailID: localStorage.getItem("user"),
//           driverEmailID: onTripDriverEmail,
//           corporateID: localStorage.getItem("corpId"),
//           Isall: 1,
//         },
//       },
//       authenticateUser
//     );

//     trip_interval = setInterval(() => {
//       sendRequest(
//         {
//           url: "/api/v1/LiveTrip/GetLiveTripDetails",
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: {
//             emailID: localStorage.getItem("user"),
//             driverEmailID: onTripDriverEmail,
//             corporateID: localStorage.getItem("corpId"),
//             Isall: 0,
//           },
//         },
//         authenticateUser
//       );
//     }, 5000);
//     sessionStorage.setItem("interval", trip_interval);
//   }

//   function clearIntervalApiCall() {
//     riderDetails = [];
//     clearInterval(trip_interval);
//     emailFlag = true;
//   }

//   function clearIntervalFligthPath() {
//     marker?.setMap(null);
//     clearInterval(pathInterval);
//     // flightPath1?.setMap(null);
//     flightPath2?.setMap(null);
//   }

//   function flightPathInterval() {
//     if (!onTripDriverEmail) {
//       flightPlanCoordinates = [];
//     }
//     if (onTripDriverEmail == null) {
//       flightPath2 = new window.google.maps.Polyline({
//         path: flightPlanCoordinates,
//       });
//     } else {
//       marker = new window.google.maps.Marker({
//         position: flightPlanCoordinates[flightPlanCoordinates.length - 1],
//         map,
//         icon: {
//           url: carIcon,
//           scaledSize: new window.google.maps.Size(34, 34),
//           anchor: new window.google.maps.Point(17, 17),
//         },
//         optimized: false,
//       });

//       pathInterval = setInterval(() => {
//         flightPath2 = new window.google.maps.Polyline({
//           path: flightPlanCoordinates,
//           // geodesic: true,
//           strokeColor: "#00b0ff",
//           strokeOpacity: 10.0,
//           strokeWeight: 3,
//         });

//         if (document.getElementsByClassName("gm-fullscreen-control")[0])
//           document.getElementsByClassName(
//             "gm-fullscreen-control"
//           )[0].style.marginTop = "45px";
//         if (flightPlanCoordinates.length > 1) {
//           flightPath2?.setMap(null);
//           flightPath2.setMap(map);
//           marker.setPosition(
//             flightPlanCoordinates[flightPlanCoordinates.length - 1]
//           );
//         } else if (flightPlanCoordinates.length > 0) {
//           flightPath2?.setMap(null);
//           flightPath2.setMap(map);
//           marker.setPosition(
//             flightPlanCoordinates[flightPlanCoordinates.length - 1]
//           );
//         }
//         if (
//           (prev_driverEmail || prev_driverEmail == null) &&
//           emailFlag &&
//           flightPlanCoordinates[flightPlanCoordinates.length - 1]?.lat
//         ) {
//           map.setCenter({
//             lat: flightPlanCoordinates[flightPlanCoordinates.length - 1]?.lat,
//             lng: flightPlanCoordinates[flightPlanCoordinates.length - 1]?.lng,
//           });
//           map.setZoom(14);
//           emailFlag = false;
//         } else if (
//           !flightPlanCoordinates[flightPlanCoordinates.length - 1]?.lat
//         ) {
//         }
//         drawLineFlag = false;
//       }, 2000);
//     }
//   }

//   function myInitMap() {
//     map = new window.google.maps.Map(document.getElementById("live-map"), {
//       center: { lat: 23.0225, lng: 72.5714 },
//       zoom: 12,
//       disableDefaultUI: true,
//       fullscreenControl: true,
//       zoomControl: true,
//     });
//     flightPathInterval();
//   }

//   window.myInitMap = myInitMap;

//   const driverSearchHandler = (e) => {
//     setFilteredDriverData(
//       props.driverData.filter(
//         (data) =>
//           data.driverName
//             ?.toLowerCase()
//             .includes(e.target.value.toLowerCase()) ||
//           data.carNumber?.toLowerCase().includes(e.target.value.toLowerCase())
//       )
//     );
//   };

//   const onTripDriverClickHandler = (
//     driverEmail,
//     isOnTrip,
//     driverName,
//     tripId
//   ) => {
//     if (isOnTrip == "1") {
//       prev_driverId = driverEmail;
//       onTripDriverName = driverName;
//       riderDetails = [];
//       setOnTripDriverEmail(driverEmail);
//       setIsLoadingRoute(true);
//       // tripID=tripId;
//       setTrpID(tripId);
//     }
//   };

//   const bookButtonClickHandler = (
//     driverImage,
//     driverName,
//     carNumber,
//     carType,
//     driverEmail,
//     carModel,
//     carColor,
//     activeShiftCororateName,
//     activeShiftCorporateId
//   ) => {
//     setBookedDriver([
//       {
//         driverImage,
//         driverName,
//         carNumber,
//         carType,
//         driverEmail,
//         carModel,
//         carColor,
//         activeShiftCororateName,
//         activeShiftCorporateId,
//       },
//     ]);
//   };

//   const toggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   // if (riderDetails.length === 0) {
//   //   return null;
//   // }

//   const firstRider = riderDetails[0];
//   const restRiders = riderDetails.slice(1);

//   const handleSearchClick = () => {
//     setIsSearchActive(true);
//     setTimeout(() => {
//       if (searchInputRef.current) {
//         searchInputRef.current.focus();
//       }
//     }, 100);
//   };

//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//     setFilteredDriverData(
//       props.driverData.filter((data) =>
//         data.driverName.toLowerCase().includes(e.target.value.toLowerCase())
//       )
//     );
//   };

//   const clearSearch = () => {
//     setSearchQuery("");
//     setFilteredDriverData(props.driverData); // Reset filtered data to all drivers
//     if (searchInputRef.current) {
//       searchInputRef.current.focus();
//       setIsSearchActive(false);
//     }
//   };
//   const handleCopyClick = (event, shuttleId) => {
//     event.stopPropagation(); // Ensure event propagation is stopped
//     navigator.clipboard.writeText(shuttleId); // Verify clipboard write operation
//     setCopiedItemId(shuttleId); // Check if copiedItemId is correctly set
//     setTimeout(() => {
//       setCopiedItemId(shuttleId); // Reset copiedItemId after timeout
//     }, 1);
//   };

//   return (
//     <React.Fragment>
//       {bookedDriver && <div className="backdrop"></div>}
//       <div
//         className="main-container"
//         id="privatedriver"
//         style={props.toggle ? { padding: "15px 0px" } : {}}
//       >
//         <div
//           className="driverlist"
//           style={{
//             pointerEvents: isLoadingRoute ? "none" : "auto",
//             opacity: isLoadingRoute ? "0.7" : "1",
//           }}
//         >
//           <div className="header">
//             {/* <h4>Driver List</h4> */}
//             {/* <p
//               style={{
//                 fontFamily: "Poppins",
//                 fontSize: "16px",
//                 fontWeight: "300",
//               }}
//             >
//               Driver List
//             </p> */}
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 height: "25px",
//               }}
//             >
//               {!isSearchActive && (
//                 <>
//                   <p
//                     style={{
//                       fontFamily: "Poppins",
//                       fontSize: "16px",
//                       fontWeight: "300",
//                       // margin: "0",
//                     }}
//                   >
//                     Driver List
//                   </p>
//                   <div
//                     onClick={handleSearchClick}
//                     style={{ cursor: "pointer" }}
//                   >
//                     <CiSearch style={{ fontSize: "20px" }} />
//                     {/* <FaSearch /> */}
//                   </div>
//                 </>
//               )}
//               {/* {isSearchActive && (
//                 <div style={{ marginLeft:'12px',display: "flex", gap: "35px" }}>
//                   <div>
//                     <TextField
//                       type="text"
//                       ref={searchInputRef}
//                       value={searchQuery}
//                       onChange={handleSearchChange}
//                       placeholder="Search Drivers"
//                       style={{ fontSize: "10px" }}
//                       InputProps={{
//                         startAdornment: (
//                           <CiSearch
//                             style={{
//                               fontSize: "18px",
//                               marginRight: "8px",
//                               color: "#999",
//                             }}
//                           />
//                         ),
//                       }}
//                       variant="standard"
//                     />
//                   </div>
//                   <div
//                     onClick={clearSearch}
//                     style={{ cursor: "pointer", marginTop: "16px" }}
//                   >
//                     <FaTimes style={{ color: "#D83434", fontSize:'16px' }} />
//                   </div>
//                 </div>
//               )} */}
//               {isSearchActive && (
//                 <div style={{ display: "flex", gap: "42px" }}>
//                   <div>
//                     <TextField
//                       type="text"
//                       ref={searchInputRef}
//                       value={searchQuery}
//                       autoFocus
//                       onChange={handleSearchChange}
//                       placeholder="Search Drivers"
//                       style={{ fontSize: "10px", width: "120%" }}
//                       InputProps={{
//                         startAdornment: (
//                           <CiSearch
//                             style={{
//                               fontSize: "18px",
//                               marginRight: "8px",
//                               color: "#999",
//                             }}
//                           />
//                         ),
//                       }}
//                       variant="standard"
//                     />
//                   </div>
//                   <div
//                     onClick={clearSearch}
//                     style={{ cursor: "pointer", alignContent: "center" }}
//                   >
//                     <FaTimes style={{ color: "#D83434", fontSize: "16px" }} />
//                   </div>
//                 </div>
//               )}
//             </div>

//             <div className="driver-filter">
//               <Tabs
//                 variant="fullWidth"
//                 sx={{
//                   button: { padding: "0", fontSize: "12px" },
//                   div: { minHeight: "35px" },
//                   span: { bottom: "5px" },
//                 }}
//                 style={{ cursor: "pointer" }}
//                 centered
//                 value={servicesTabbarValue}
//                 onChange={(e, newValue) => {
//                   if (newValue == "0")
//                     // setFilteredDriverData(props.driverData.filter(data => data.driverName.toLowerCase().includes(searchInputRef?.current?.value?.toLowerCase())));
//                     setFilteredDriverData(props.driverData);
//                   else if (newValue == "2")
//                     setFilteredDriverData(
//                       props.driverData.filter((data) => data?.isOnTrip == "1")
//                     );
//                   else if (newValue == "1")
//                     setFilteredDriverData(
//                       props.driverData.filter(
//                         (data) => data?.isOnline == "1" && data?.isOnTrip == "0"
//                       )
//                     );
//                   setDriverFilterType(
//                     newValue == "0"
//                       ? "All"
//                       : newValue == "1"
//                       ? "Online"
//                       : "On Trip"
//                   );
//                   setOnTripDriverEmail("");
//                   setServicesTabbarValue(newValue);
//                 }}
//                 aria-label="basic tabs example"
//               >
//                 <Tab
//                   label="All"
//                   style={{ fontWeight: "bold" }}
//                   sx={{ button: { minHeight: "35px" } }}
//                 />
//                 <Tab
//                   label="Online"
//                   style={{ fontWeight: "bold" }}
//                   sx={{ button: { minHeight: "35px" } }}
//                 />
//                 <Tab
//                   label="On Trip"
//                   style={{ fontWeight: "bold" }}
//                   sx={{ button: { minHeight: "35px" } }}
//                 />
//               </Tabs>
//             </div>
//           </div>
//           <div className="driverDetails">
//             {props.isLoading && (
//               <CircularProgress
//                 sx={{
//                   position: "absolute",
//                   top: "40%",
//                   left: "45%",
//                   transform: "translate(-50%,-50%)",
//                 }}
//               />
//             )}
//             {filteredDriverData?.length < 1 && !props.isLoading && (
//               <div style={{ textAlign: "center", marginTop: "20px" }}>
//                 No Drivers Available
//               </div>
//             )}
//             {filteredDriverData?.map((ele) => {
//               return (
//                 <div
//                   id={ele.driverEmail}
//                   key={ele.driverEmail}
//                   className={`driverSingleData ${
//                     ele.isOnTrip == "1" ? "driverContainer" : ""
//                   } ${
//                     onTripDriverEmail === ele.driverEmail ? "currentDriver" : ""
//                   }`}
//                   onClick={() =>
//                     onTripDriverClickHandler(
//                       ele?.driverEmail,
//                       ele?.isOnTrip,
//                       ele?.driverName,
//                       ele?.tripId
//                     )
//                   }
//                 >
//                   <div
//                     style={{
//                       display: "flex",
//                       gap: "10px",
//                       alignItems: "center",
//                     }}
//                   >
//                     <div style={{ position: "relative", alignSelf: "center" }}>
//                       <img className="driverPhoto" src={ele.driverImage} />
//                       <p
//                         className={
//                           ele?.isOnTrip == "1"
//                             ? "ontrip"
//                             : ele.isOnline == "1"
//                             ? "online"
//                             : "offline"
//                           // : "offline"
//                         }
//                       ></p>
//                     </div>
//                     <div style={{ display: "flex", flexDirection: "column" }}>
//                       <span className="drivername">{ele.driverName}</span>
//                       <span className="carnumber">
//                         {ele.carNumber.replaceAll("-", "")} -{" "}
//                         {(ele.isOnTrip == "1" ||
//                           (ele.isOnline == "1" &&
//                             ele.isShiftStarted == "1")) && (
//                           <span
//                             style={{
//                               fontSize: "10px",
//                               fontFamily: "Poppins",
//                               textTransform: "capitalize",
//                               color: "gray",
//                             }}
//                           >
//                             {ele.isOnTrip == "0" ? "private" : ele?.tripType}
//                           </span>
//                         )}
//                       </span>
//                       {localStorage.getItem("roleId") == "1" && (
//                         <span
//                           style={{
//                             fontSize: "10px",
//                             fontFamily: "Poppins",
//                             textTransform: "capitalize",
//                             color: "gray",
//                           }}
//                         >
//                           {ele?.corporateName}
//                         </span>
//                       )}

//                       <div
//                         style={{
//                           display: "flex",
//                           gap: "10px",
//                           alignItems: "center",
//                         }}
//                       ></div>
//                     </div>
//                   </div>
//                   {(ele?.isOnTrip == "1" ||
//                     (ele?.isOnline == "1" && ele?.isShiftStarted == "1")) && (
//                     <button
//                       className={
//                         ele?.isOnTrip == "1" ? "onTripButtton" : "bookButton"
//                       }
//                       onClick={
//                         ele?.isOnTrip != "1"
//                           ? () =>
//                               bookButtonClickHandler(
//                                 ele?.driverImage,
//                                 ele?.driverName,
//                                 ele?.carNumber,
//                                 ele?.vehicleType,
//                                 ele?.driverEmail,
//                                 ele?.carModel,
//                                 ele?.carColor,
//                                 ele?.activeShiftCororateName,
//                                 ele?.activeShiftCorporateId
//                               )
//                           : null
//                       }
//                     >
//                       {ele?.isOnTrip == "1" ? "On Trip" : "Book"}
//                     </button>
//                   )}
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//         <div className="privateDriverMap-container">
//           <div className="mapText">
//             <span
//               style={{
//                 fontFamily: "Poppins",
//                 fontSize: "16px",
//                 fontWeight: "300",
//               }}
//             >
//               Live Trip Tracker
//             </span>
//           </div>
//           <div className="livetrip" id="live-map"></div>
//           {/* {riderDetails.length > 0 && (
//             <div className="riderInfoContainer">
//               <div
//                 style={{ display: "flex", gap: "15px", alignItems: "center" }}
//               >
//                 <FaRegUserCircle />
//                 {riderDetails?.map((data) => (
//                   <span>{data.riderName}</span>
//                 ))}
//               </div>
//               <div
//                 style={{ display: "flex", gap: "15px", alignItems: "center" }}
//               >
//                 <FiPhoneCall />
//                 {riderDetails?.map((data) => (
//                   <span>{"+" + data.riderMobileNumber}</span>
//                 ))}
//               </div>
//             </div>
//           )}
//           */}

//           {riderDetails?.length > 0 && (
//             <div
//               className="riderInfoContainer"
//               style={{
//                 border: "1px solid #ccc",
//                 padding: "10px",
//                 borderRadius: "8px",
//                 width: "auto",
//                 height: "auto",
//                 // background:'transparent'
//                 opacity: "0.9",
//               }}
//             >
//               {/* New Code */}
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   // marginBottom: "8px",
//                   // marginTop: "4px",
//                 }}
//               >
//                 <div
//                   style={{
//                     display: "flex",
//                     // gap: "10px",
//                     alignItems: "center",
//                     // marginBottom: "5px",
//                   }}
//                 >
//                   <Tooltip
//                     title={copiedItemId === trpID ? "Copied!" : "Copy Route ID"}
//                     arrow
//                   >
//                     <IconButton style={{marginLeft:"-5%"}}
//                       onClick={(event) => handleCopyClick(event, trpID)}
//                     >
//                       <FileCopyIcon style={{ width: "14px" }} />
//                     </IconButton>
//                   </Tooltip>
//                   <div>
//                     <div> {trpID.substring(0, 8) + "..."}</div>
//                   </div>
//                 </div>
//               </div>

//               {/* New Code with the main Driver Name */}
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   // marginBottom: "8px",
//                   // marginTop: "4px",
//                 }}
//               >
//                 <div
//                   style={{
//                     display: "flex",
//                     gap: "10px",
//                     alignItems: "center",
//                     marginBottom: "5px",
//                   }}
//                 >
//                   <FaRegUserCircle />
//                   <div>
//                     <div>{firstRider.riderName}</div>
//                     <div style={{ fontSize: "11px", alignItems: "center" }}>
//                       {firstRider.riderMobileNumber}
//                     </div>
//                   </div>
//                   {restRiders.length > 1 && (
//                   <button
//                     onClick={toggleExpand}
//                     style={{
//                       border: "none",
//                       background: "none",
//                       cursor: "pointer",
//                     }}
//                   >
//                     {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                   </button>
//                 )}
//                 </div>
//               </div>

//               {isExpanded && restRiders.length > 0 && (
//                 <div
//                   className="additionalRiders"
//                   style={{ maxHeight: "200px", overflowY: "auto" }}
//                 >
//                   {restRiders?.map((rider, index) => (
//                     <div
//                       key={index}
//                       style={{
//                         display: "flex",
//                         flexDirection: "column",
//                         marginBottom: "8px",
//                         marginTop: "4px",
//                       }}
//                     >
//                       <div
//                         style={{
//                           display: "flex",
//                           gap: "10px",
//                           alignItems: "center",
//                           marginBottom: "5px",
//                         }}
//                       >
//                         <FaRegUserCircle />
//                         <div>
//                           <div>{rider.riderName}</div>
//                           <div
//                             style={{ fontSize: "11px", alignItems: "center" }}
//                           >
//                             {rider.riderMobileNumber}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </div>
//           )}

//           {isLoadingRoute && (
//             <div
//               style={{
//                 position: "absolute",
//                 top: "40px",
//                 left: "0",
//                 width: "100%",
//                 height: "calc(100% - 40px)",
//                 backgroundColor: "white",
//                 opacity: "0.5",
//               }}
//             ></div>
//           )}
//           {isLoadingRoute && <Loading driver="true" />}
//         </div>
//       </div>
//       {bookedDriver && (
//         <DriverBooking
//           bookedDriver={bookedDriver}
//           setBookedDriver={setBookedDriver}
//           riderData={props.riderData}
//           tripRequestStatusFunc={(status) => {
//             setTripRequestStatus(status);
//             setBookedDriver(false);
//           }}
//         />
//       )}
//       {isTripEnded && (
//         <Message
//           type="success"
//           message={onTripDriverName + "'s Trip has been ended"}
//         />
//       )}
//     </React.Fragment>
//   );
// };

// export default LiveTracking;

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FaRegUserCircle, FaTimes, FaSearch } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { FiPhoneCall } from "react-icons/fi";
import CryptoJS from "crypto-js";
import WestIcon from "@mui/icons-material/West";

import CircularProgress from "@mui/material/CircularProgress";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import useHttp from "../../Hooks/use-http";
import DriverBooking from "./PrivateDriverBooking";
import Loading from "../../Loading/Loading";
import Message from "../../Modal/Message";
import "./LiveTracking.css";
import startPoint from "../../Assets/dropIcon.png";
import endPoint from "../../Assets/pickIcon.png";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { LiveLocationCallDuration } from "../DurationGap/DurationCall";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";
import { carIcon } from "../../Constant";
let prev_driverEmail = "";
let flightPlanCoordinates = [];
let emailFlag = true;
let driverFlag = true;
let trip_interval = "";
let pathInterval = "";
let myFlag = 1;
let map;
let prev_driverId = "";
let flightPath1;
let flightPath2;
let marker;
let startPointMarker;
let endPointMarker;
var numDeltas = 100;
var delay = 20; //milliseconds
var i = 0;
var deltaLat;
var deltaLng;

let drawLineFlag = false;
let journeyStart = 0;
let onTripDriverName = "";
let riderDetails = [];
let onlineDriversMarker = [];
const LiveTracking = (props) => {
  const [bookedDriver, setBookedDriver] = useState(false);
  const [tripIdOfOnTrip, setTripIdOfOnTrip] = useState("");
  const [onTripDriverEmail, setOnTripDriverEmail] = useState(null);
  const [tripRequestStatus, setTripRequestStatus] = useState(false);
  const [isLoadingRoute, setIsLoadingRoute] = useState(false);
  const [isTripEnded, setIsTripEnded] = useState(false);
  const [driverFilterType, setDriverFilterType] = useState("All");
  const [filteredDriverData, setFilteredDriverData] = useState([]);
  const [servicesTabbarValue, setServicesTabbarValue] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [trpID, setTrpID] = useState(null);
  const [copiedItemId, setCopiedItemId] = useState(null);
  const searchInputRef = useRef();
  const history = useHistory();

  useEffect(() => {
    // debugger;
    if (props?.driverData?.length > 0 && !props.isLoading) {
      setFilteredDriverData(props.driverData);
    }
  }, [props.driverData, props.isLoading]);

  function transition() {
    i = 0;
    deltaLat =
      (flightPlanCoordinates[flightPlanCoordinates?.length - 1].lat -
        flightPlanCoordinates[flightPlanCoordinates?.length - 2].lat) /
      numDeltas;
    deltaLng =
      (flightPlanCoordinates[flightPlanCoordinates?.length - 1].lng -
        flightPlanCoordinates[flightPlanCoordinates?.length - 2].lng) /
      numDeltas;
    moveMarker();
  }

  function moveMarker() {
    flightPlanCoordinates[flightPlanCoordinates?.length - 2].lat += deltaLat;
    flightPlanCoordinates[flightPlanCoordinates?.length - 2].lng += deltaLng;
    var latlng = new window.google.maps.LatLng(
      flightPlanCoordinates[flightPlanCoordinates?.length - 2].lat,
      flightPlanCoordinates[flightPlanCoordinates?.length - 2].lng
    );
    marker.setPosition(latlng);
    if (i != numDeltas) {
      i++;
      setTimeout(moveMarker, delay);
    }
  }

  useEffect(() => {
    prev_driverEmail = "";
    riderDetails = [];
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDHdkmGjsfNqasFs6m9CooShFZsqWHcdUs&callback=myInitMap&libraries=places&v=weekly";
    script.async = true;
    document.body.appendChild(script);
  }, [onTripDriverEmail, driverFilterType]);

  if (
    (prev_driverEmail && prev_driverEmail !== onTripDriverEmail && myFlag) ||
    (prev_driverEmail == null && onTripDriverEmail == null)
  ) {
    // console.log("here", trip_interval);
    clearIntervalApiCall();
    clearIntervalFligthPath();
    // intervalApiCall();
    setTimeout(() => {
      flightPathInterval();
    });
    myFlag = 0;
  } else myFlag = 1;

  // original one====================
  // const authenticateUser = (data) => {
  //   if (!onTripDriverEmail) {
  //     if (data) {
  //       try {
  //         let driverDetails = JSON.parse(data);
  //         onlineDriversMarker.forEach((marker) => marker?.setMap(null));
  //         onlineDriversMarker = [];
  //         const infoWindow = new window.google.maps.InfoWindow();

  //         driverDetails.forEach((driver, i) => {
  //           if (
  //             driver.Latitude > 0 &&
  //             (driverFilterType === "Online"
  //               ? !driver.TripID
  //               : driverFilterType === "On Trip"
  //               ? driver.TripID
  //               : true)
  //           ) {
  //             // console.log("Live Tracking: "+parseFloat(driverDetails[i]?.Latitude) + ","+parseFloat(driverDetails[i]?.Longitude))

  //             onlineDriversMarker[i] = new window.google.maps.Marker({
  //               position: {
  //                 lat: parseFloat(driver?.Latitude),
  //                 lng: parseFloat(driver?.Longitude),
  //               },
  //               map,
  //               icon: {
  //                 url: carIcon,
  //                 scaledSize: new window.google.maps.Size(34, 34),
  //                 anchor: new window.google.maps.Point(17, 17),
  //               },
  //               myTitle: `${driver.FullName}`,
  //               Title: `${driver.FullName}`,
  //             });
  //             onlineDriversMarker[i]?.addListener("click", () => {
  //               infoWindow.close();
  //               infoWindow.setHeaderContent(onlineDriversMarker[i].myTitle);
  //               infoWindow.open(
  //                 onlineDriversMarker[i].getMap(),
  //                 onlineDriversMarker[i]
  //               );
  //             });
  //           }
  //         });

  //         const markerUrl =
  //           carIcon;
  //         setTimeout(() => {
  //           onlineDriversMarker.forEach((marker, i) => {
  //             let markerSrc = document.querySelectorAll(`[src="${markerUrl}"]`);
  //             if (marker && markerSrc[i]) {
  //               markerSrc[
  //                 i
  //               ].style.transform = `rotate(${driverDetails[i].Bearing}deg)`;
  //             }
  //           });
  //         }, 2000);
  //       } catch (error) {
  //         console.error("Error parsing driver details JSON:", error);
  //       }
  //     }
  //   } else {
  //     if (data?.TripLocations?.length > 0) {
  //       const reversedTripLocations = [...data.TripLocations].reverse();
  //       if (!driverFlag) {
  //         // const lastLocation = data.TripLocations[data.TripLocations.length - 1];
  //         // const lastLocation =
  //         // data.TripLocations[data.TripLocations.length - 1];
  //         const lastLocation = reversedTripLocations[0];
  //         // const lastLocation=reversedTripLocations[reversedTripLocations.length-1]

  //         const lat = parseFloat(lastLocation?.LL?.split(",")?.[0]) || 0;
  //         const lng = parseFloat(lastLocation?.LL?.split(",")?.[1]) || 0;

  //         if (
  //           flightPlanCoordinates[flightPlanCoordinates.length - 1]?.lat !==
  //             lat &&
  //           flightPlanCoordinates[flightPlanCoordinates.length - 1]?.lng !== lng
  //         ) {
  //           flightPlanCoordinates.push({ lat, lng });
  //         }
  //       } else {
  //         flightPlanCoordinates = [];
  //         // data?.TripLocations.forEach((location) => {
  //           reversedTripLocations.forEach((location) => {
  //           // reversedTripLocations.forEach(location => {
  //           const lat = parseFloat(location?.LL?.split(",")?.[0]) || 0;
  //           const lng = parseFloat(location?.LL?.split(",")?.[1]) || 0;
  //           flightPlanCoordinates.push({ lat, lng });
  //         });

  //         drawLineFlag = true;
  //         driverFlag = false;
  //       }

  //       journeyStart = 1;

  //       const markerUrl =
  //         carIcon;
  //       let markerSrc = document.querySelector(`[src="${markerUrl}"]`);
  //       if (marker && markerSrc) {
  //         markerSrc.style.transform = `rotate(${
  //           // data?.TripLocations[data.TripLocations.length - 1].Bearing
  //           reversedTripLocations[0]?.Bearing
  //         }deg)`;
  //         // markerSrc.style.transform = `rotate(${data?.TripLocations[0].Bearing}deg)`;
  //         // markerSrc.style.transform = `rotate(${reversedTripLocations[0].Bearing}deg)`;
  //       }

  //       setIsTripEnded(false);
  //     } else if (data?.LivetripStatus?.toLowerCase() === "ended") {
  //       setIsTripEnded(true);
  //       flightPlanCoordinates = [];
  //     } else {
  //       setIsTripEnded(false);
  //     }

  //     // if (!(data?.LivetripStatus?.toLowerCase() === "ended" || data?.LivetripStatus?.toLowerCase() === "arrived")) {
  //     if (
  //       !(
  //         data?.Tripdetails?.TripStatus?.toLowerCase() === "ended" ||
  //         data?.Tripdetails?.TripStatus?.toLowerCase() === "arrived"
  //       )
  //     ) {
  //       if (startPointMarker) startPointMarker.setMap(null);
  //       if (endPointMarker) endPointMarker.setMap(null);
  //       // riderDetails = [];
  //       // Initialize riderDetails only if it's empty
  //       riderDetails = riderDetails.length === 0 ? [] : riderDetails;
  //       let tripDetails = {};

  //       // riderDetails.push(data?.TripRiders);
  //       tripDetails = data?.Tripdetails;
  //       const newRiders = data?.TripRiders || [];
  //       // console.log("kjsjfljd", riderDetails)
  //       // data.Livetrip?.forEach(trip => {
  //       const uniqueRiders = newRiders.filter(
  //         (newRider) =>
  //           !riderDetails.some(
  //             (existingRider) => existingRider.RiderID === newRider.RiderID
  //           )
  //       );

  //       // Use array spread to add only unique riders
  //       riderDetails = [...riderDetails, ...uniqueRiders];

  //       const pickupLat =
  //         parseFloat(tripDetails?.ActualPickupLatLng?.split(",")?.[0]) ||
  //         parseFloat(tripDetails?.PickupLatLng?.split(",")?.[0]) ||
  //         0;
  //       const pickupLng =
  //         parseFloat(tripDetails?.ActualPickupLatLng?.split(",")?.[1]) ||
  //         parseFloat(tripDetails?.PickupLatLng?.split(",")?.[1]) ||
  //         0;

  //       // startPointMarker = new window.google.maps.Marker({
  //       //   position: tripDetails?.ActualPickupName
  //       //     ? tripDetails?.ActualPickupName
  //       //     : tripDetails?.PickupAddress && {
  //       //         lat:
  //       //           parseFloat(
  //       //             tripDetails?.ActualPickupLatLng?.split(",")?.[0]
  //       //           ) ||
  //       //           parseFloat(tripDetails?.PickupLatLng?.split(",")?.[0]) ||
  //       //           0,
  //       //         lng:
  //       //           parseFloat(
  //       //             tripDetails?.ActualPickupLatLng?.split(",")?.[1]
  //       //           ) ||
  //       //           parseFloat(tripDetails?.PickupLatLng?.split(",")?.[1]) ||
  //       //           0,
  //       //       },
  //       //   map,
  //       //   icon: startPoint,
  //       //   myTitle: `${
  //       //     tripDetails?.ActualPickupName ||
  //       //     tripDetails?.PickupAddress ||
  //       //     "Pickup"
  //       //   }`,
  //       // });
  //       if (pickupLat !== 0 || pickupLng !== 0) {
  //         startPointMarker = new window.google.maps.Marker({
  //           position: { lat: pickupLat, lng: pickupLng },
  //           map,
  //           icon: startPoint,
  //           myTitle:
  //             tripDetails?.ActualPickupName ||
  //             tripDetails?.PickupAddress ||
  //             "Pickup",
  //         });
  //       }
  //       // Example for endPointMarker
  //       endPointMarker = new window.google.maps.Marker({
  //         position: {
  //           lat:
  //             parseFloat(tripDetails?.ActualDropOffLatLng.split(",")?.[0]) ||
  //             parseFloat(tripDetails?.DropOffLatLng.split(",")?.[0]) ||
  //             0,
  //           lng:
  //             parseFloat(tripDetails?.ActualDropOffLatLng.split(",")?.[1]) ||
  //             parseFloat(tripDetails?.DropOffLatLng.split(",")?.[1]) ||
  //             0,
  //         },
  //         map,
  //         icon: endPoint,
  //         myTitle: `${tripDetails?.DropoffAddress || "Dropoff"}`,
  //       });

  //       var bounds = new window.google.maps.LatLngBounds();
  //       bounds.extend(
  //         new window.google.maps.LatLng(
  //           parseFloat(tripDetails?.PickupLatLng?.split(",")?.[0]) || 0,
  //           parseFloat(tripDetails?.PickupLatLng?.split(",")?.[1]) || 0
  //         )
  //       );
  //       bounds.extend(
  //         new window.google.maps.LatLng(
  //           parseFloat(tripDetails?.DropoffLatitude.split(",")?.[0]) || 0,
  //           parseFloat(tripDetails?.DropoffLongitude.split(",")?.[1]) || 0
  //         )
  //       );

  //       const infoWindow = new window.google.maps.InfoWindow();
  //       startPointMarker?.addListener("mouseover", () => {
  //         infoWindow.close();
  //         infoWindow.setHeaderContent(startPointMarker.myTitle);
  //         infoWindow.open(startPointMarker.getMap(), startPointMarker);
  //       });
  //       endPointMarker?.addListener("mouseover", () => {
  //         infoWindow.close();
  //         infoWindow.setHeaderContent(endPointMarker.myTitle);
  //         infoWindow.open(endPointMarker.getMap(), endPointMarker);
  //       });
  //     }
  //   }
  //   setIsLoadingRoute(false);
  // };

  // ========================================

  const authenticateUser = (data, key) => {
    // // Reset states to avoid conflicts
    // onlineDriversMarker.forEach((marker) => marker?.setMap(null)); // Clear markers from the map
    // onlineDriversMarker = [];
    // flightPlanCoordinates = [];
    // if (startPointMarker) startPointMarker.setMap(null); // Remove start point marker
    // if (endPointMarker) endPointMarker.setMap(null); // Remove end point marker
    // startPointMarker = null;
    // endPointMarker = null;
    // riderDetails = []; // Clear rider details
    // setIsTripEnded(false); // Reset trip status
    // setIsLoadingRoute(false); // Reset loading state

    if (!onTripDriverEmail) {
      if (data) {

        try {
          let driverDetails = JSON.parse(data);
          onlineDriversMarker.forEach((marker) => marker?.setMap(null));
          onlineDriversMarker = [];
          const infoWindow = new window.google.maps.InfoWindow();

          driverDetails.forEach((driver, i) => {
            if (
              driver.Latitude > 0 &&
              (driverFilterType === "Online"
                ? !driver.TripID
                : driverFilterType === "On Trip"
                ? driver.TripID
                : true)
            ) {
              onlineDriversMarker[i] = new window.google.maps.Marker({
                position: {
                  lat: parseFloat(driver?.Latitude),
                  lng: parseFloat(driver?.Longitude),
                },
                map,
                icon: {
                  url: carIcon,
                  scaledSize: new window.google.maps.Size(34, 34),
                  anchor: new window.google.maps.Point(17, 17),
                },
                myTitle: `${driver.FullName}`,
                Title: `${driver.FullName}`,
              });

              onlineDriversMarker[i]?.addListener("click", () => {
                infoWindow.close();
                infoWindow.setHeaderContent(onlineDriversMarker[i].myTitle);
                infoWindow.open(
                  onlineDriversMarker[i].getMap(),
                  onlineDriversMarker[i]
                );
              });
            }
          });

          const markerUrl = carIcon;
          setTimeout(() => {
            onlineDriversMarker.forEach((marker, i) => {
              let markerSrc = document.querySelectorAll(`[src="${markerUrl}"]`);
              if (marker && markerSrc[i]) {
                markerSrc[
                  i
                ].style.transform = `rotate(${driverDetails[i].Bearing}deg)`;
              }
            });
          }, 2000);
        } catch (error) {
          console.error("Error parsing driver details JSON:", error);
        }
      }
    } else {
      console.log("tripLocation ONe before if", key);
      if (key === "all") {
        // // Reset states to avoid conflicts
        onlineDriversMarker.forEach((marker) => marker?.setMap(null)); // Clear markers from the map
        onlineDriversMarker = [];
        flightPlanCoordinates = [];
        if (startPointMarker) startPointMarker.setMap(null); // Remove start point marker
        if (endPointMarker) endPointMarker.setMap(null); // Remove end point marker
        startPointMarker = null;
        endPointMarker = null;
        riderDetails = []; // Clear rider details
        setIsTripEnded(false); // Reset trip status
        setIsLoadingRoute(false); // Reset loading state
      }

      if (data?.TripLocations?.length > 0) {

        const reversedTripLocations = [...data.TripLocations].reverse();
        if (!driverFlag) {
          const lastLocation = reversedTripLocations[0];
          const lat = parseFloat(lastLocation?.LL?.split(",")?.[0]) || 0;
          const lng = parseFloat(lastLocation?.LL?.split(",")?.[1]) || 0;

          if (
            flightPlanCoordinates[flightPlanCoordinates.length - 1]?.lat !==
              lat &&
            flightPlanCoordinates[flightPlanCoordinates.length - 1]?.lng !== lng
          ) {
            flightPlanCoordinates.push({ lat, lng });
          }
        } else {
          flightPlanCoordinates = [];
          reversedTripLocations.forEach((location) => {
            const lat = parseFloat(location?.LL?.split(",")?.[0]) || 0;
            const lng = parseFloat(location?.LL?.split(",")?.[1]) || 0;
            flightPlanCoordinates.push({ lat, lng });
          });

          drawLineFlag = true;
          driverFlag = false;
        }

        journeyStart = 1;

        const markerUrl = carIcon;
        let markerSrc = document.querySelector(`[src="${markerUrl}"]`);
        if (marker && markerSrc) {
          markerSrc.style.transform = `rotate(${reversedTripLocations[0]?.Bearing}deg)`;
        }

        setIsTripEnded(false);
      } else if (data?.LivetripStatus?.toLowerCase() === "ended") {
        onlineDriversMarker.forEach((marker) => marker?.setMap(null));
        onlineDriversMarker = [];
        flightPlanCoordinates = [];
        setIsTripEnded(true);
        
      } else {
        onlineDriversMarker.forEach((marker) => marker?.setMap(null));
        onlineDriversMarker = [];
        flightPlanCoordinates = [];
        setIsTripEnded(false);
      }

      if (
        !(
          data?.Tripdetails?.TripStatus?.toLowerCase() === "ended" ||
          data?.Tripdetails?.TripStatus?.toLowerCase() === "arrived"
        )
      ) {
        const tripDetails = data?.Tripdetails || {};
        const newRiders = data?.TripRiders || [];
        const uniqueRiders = newRiders.filter(
          (newRider) =>
            !riderDetails.some(
              (existingRider) => existingRider.RiderID === newRider.RiderID
            )
        );
        riderDetails = [...riderDetails, ...uniqueRiders];

        const pickupLat =
          parseFloat(tripDetails?.ActualPickupLatLng?.split(",")?.[0]) ||
          parseFloat(tripDetails?.PickupLatLng?.split(",")?.[0]) ||
          0;
        const pickupLng =
          parseFloat(tripDetails?.ActualPickupLatLng?.split(",")?.[1]) ||
          parseFloat(tripDetails?.PickupLatLng?.split(",")?.[1]) ||
          0;

        if (pickupLat !== 0 || pickupLng !== 0) {
          startPointMarker = new window.google.maps.Marker({
            position: { lat: pickupLat, lng: pickupLng },
            map,
            icon: startPoint,
            myTitle:
              tripDetails?.ActualPickupName ||
              tripDetails?.PickupAddress ||
              "Pickup",
          });
        }

        endPointMarker = new window.google.maps.Marker({
          position: {
            lat:
              parseFloat(tripDetails?.ActualDropOffLatLng?.split(",")?.[0]) ||
              parseFloat(tripDetails?.DropOffLatLng?.split(",")?.[0]) ||
              0,
            lng:
              parseFloat(tripDetails?.ActualDropOffLatLng?.split(",")?.[1]) ||
              parseFloat(tripDetails?.DropOffLatLng?.split(",")?.[1]) ||
              0,
          },
          map,
          icon: endPoint,
          myTitle: `${tripDetails?.DropoffAddress || "Dropoff"}`,
        });

        const infoWindow = new window.google.maps.InfoWindow();
        startPointMarker?.addListener("mouseover", () => {
          infoWindow.close();
          infoWindow.setHeaderContent(startPointMarker.myTitle);
          infoWindow.open(startPointMarker.getMap(), startPointMarker);
        });
        endPointMarker?.addListener("mouseover", () => {
          infoWindow.close();
          infoWindow.setHeaderContent(endPointMarker.myTitle);
          infoWindow.open(endPointMarker.getMap(), endPointMarker);
        });
      }
    }
    setIsLoadingRoute(false);
  };

  const { isLoading, sendRequest } = useHttp();

  const location = useLocation();
  // useEffect(() => {
  //   if(location.pathname === "/dashboard"){
  //     if (onTripDriverEmail) intervalApiCall();

  //   }
  // }, [onTripDriverEmail, location.pathname]);

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      if (onTripDriverEmail) intervalApiCall();
    } else {
      clearInterval(trip_interval); // Clear the interval if not on the dashboard
    }

    // Cleanup when the component unmounts or the route changes
    return () => clearInterval(trip_interval);
  }, [onTripDriverEmail, location.pathname]);

  useEffect(() => {
    if (!onTripDriverEmail) {
      setIsLoadingRoute(true);
      var secretkey = "f080786e3a348458a621e2fa4c267ad8";
      var key = CryptoJS.enc.Utf8.parse(secretkey);
      var iv = CryptoJS.enc.Utf8.parse("84jfkfndl3ybdfkf");

      let data = `FORMID|JSONDATA|JSONDATA|{"FORMID":"GETDRIVERLOCATIONS_PD","VehicleTypes":{"CorporateID":"${localStorage.getItem(
        "adminDepartmentID"
      )}"},"Country":"INDIA","City":"AHMEDABAD","userId": "${localStorage.getItem(
        "user"
      )}"}`;
      var cipherText = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString();
      sendRequest(
        {
          url: "/api/v1/DriverList/DriveronlineLocations",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            cipherText: encodeURIComponent(cipherText),
          },
        },
        (data) => authenticateUser(data)
      );
    }
  }, [onTripDriverEmail, driverFilterType]);

  function intervalApiCall() {
    flightPlanCoordinates = [];
    if (prev_driverEmail == null) driverFlag = true;
    else {
      prev_driverEmail && prev_driverEmail === onTripDriverEmail
        ? (driverFlag = false)
        : (driverFlag = true);
      prev_driverEmail = onTripDriverEmail;
    }

    sendRequest(
      {
        url: "/api/v1/Trips/GetTripDetails",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          EmailID: localStorage.getItem("user"),
          // driverEmailID: onTripDriverEmail,
          CorporateID: localStorage.getItem("corpId"),
          roleID: localStorage.getItem("roleId"),
          // Isall: 1,
          LocationCount: 9999,
          TripID: tripIdOfOnTrip,
        },
      },
      (data) => authenticateUser(data, "all")
    );

    trip_interval = setInterval(() => {
      sendRequest(
        {
          url: "/api/v1/Trips/GetTripDetails",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            // emailID: localStorage.getItem("user"),
            // driverEmailID: onTripDriverEmail,
            // corporateID: localStorage.getItem("corpId"),
            // Isall: 0,
            emailID: localStorage.getItem("user"),
            roleID: localStorage.getItem("roleId"),
            // driverEmailID: onTripDriverEmail,
            corporateID: localStorage.getItem("corpId"),
            // Isall: 1,
            tripID: tripIdOfOnTrip,
            locationCount: 1,
          },
        },
        (data) => authenticateUser(data, "one")
      );
      // }, 5000);
    }, 5000);

    sessionStorage.setItem("interval", trip_interval);
  }

  function clearIntervalApiCall() {
    riderDetails = [];
    clearInterval(trip_interval);
    emailFlag = true;
  }

  function clearIntervalFligthPath() {
    marker?.setMap(null);
    clearInterval(pathInterval);
    // flightPath1?.setMap(null);
    flightPath2?.setMap(null);
  }

  function flightPathInterval() {
    if (!onTripDriverEmail) {
      flightPlanCoordinates = [];
    }
    if (onTripDriverEmail == null) {
      flightPath2 = new window.google.maps.Polyline({
        path: flightPlanCoordinates,
      });
    } else {
      marker = new window.google.maps.Marker({
        position: flightPlanCoordinates[flightPlanCoordinates?.length - 1],
        map,
        icon: {
          url: carIcon,
          scaledSize: new window.google.maps.Size(34, 34),
          anchor: new window.google.maps.Point(17, 17),
        },
        optimized: false,
      });

      pathInterval = setInterval(() => {
        flightPath2 = new window.google.maps.Polyline({
          path: flightPlanCoordinates,
          // geodesic: true,
          strokeColor: "#00b0ff",
          strokeOpacity: 10.0,
          strokeWeight: 3,
        });

        if (document.getElementsByClassName("gm-fullscreen-control")[0])
          document.getElementsByClassName(
            "gm-fullscreen-control"
          )[0].style.marginTop = "45px";
        if (flightPlanCoordinates?.length > 1) {
          flightPath2?.setMap(null);
          flightPath2.setMap(map);
          marker.setPosition(
            flightPlanCoordinates[flightPlanCoordinates?.length - 1]
          );
        } else if (flightPlanCoordinates?.length > 0) {
          flightPath2?.setMap(null);
          flightPath2.setMap(map);
          marker.setPosition(
            flightPlanCoordinates[flightPlanCoordinates?.length - 1]
          );
        }
        if (
          (prev_driverEmail || prev_driverEmail == null) &&
          emailFlag &&
          flightPlanCoordinates[flightPlanCoordinates?.length - 1]?.lat
        ) {
          map.setCenter({
            lat: flightPlanCoordinates[flightPlanCoordinates?.length - 1]?.lat,
            lng: flightPlanCoordinates[flightPlanCoordinates?.length - 1]?.lng,
          });
          map.setZoom(14);
          emailFlag = false;
        } else if (
          !flightPlanCoordinates[flightPlanCoordinates?.length - 1]?.lat
        ) {
        }
        drawLineFlag = false;
      }, 2000);
      // },{LiveLocationCallDuration});
    }
  }

  function myInitMap() {
    map = new window.google.maps.Map(document.getElementById("live-map"), {
      center: { lat: 23.0225, lng: 72.5714 },
      zoom: 12,
      disableDefaultUI: true,
      fullscreenControl: true,
      zoomControl: true,
    });
    flightPathInterval();
  }

  window.myInitMap = myInitMap;

  const driverSearchHandler = (e) => {
    setFilteredDriverData(
      props.driverData.filter(
        (data) =>
          data.driverName
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          data.carNumber?.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const onTripDriverClickHandler = (
    driverEmail,
    isOnTrip,
    driverName,
    tripId
  ) => {
    if (isOnTrip == "1") {
      prev_driverId = driverEmail;
      onTripDriverName = driverName;
      riderDetails = [];
      setOnTripDriverEmail(driverEmail);
      setIsLoadingRoute(true);
      setTrpID(tripId);
    }
  };

  const bookButtonClickHandler = (
    driverImage,
    driverName,
    carNumber,
    carType,
    driverEmail,
    carModel,
    carColor,
    activeShiftCororateName,
    activeShiftCorporateId
  ) => {
    setBookedDriver([
      {
        driverImage,
        driverName,
        carNumber,
        carType,
        driverEmail,
        carModel,
        carColor,
        activeShiftCororateName,
        activeShiftCorporateId,
      },
    ]);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // if (riderDetails.length === 0) {
  //   return null;
  // }
  const firstRider = riderDetails?.[0];
  const restRiders = riderDetails?.slice(1);

  const handleSearchClick = () => {
    setIsSearchActive(true);
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 100);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setFilteredDriverData(
      props.driverData.filter((data) =>
        data.driverName.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const clearSearch = () => {
    setSearchQuery("");
    setFilteredDriverData(props.driverData); // Reset filtered data to all drivers
    if (searchInputRef.current) {
      searchInputRef.current.focus();
      setIsSearchActive(false);
    }
  };

  const handleCopyClick = (event, shuttleId) => {
    event.stopPropagation(); // Ensure event propagation is stopped
    navigator.clipboard.writeText(shuttleId); // Verify clipboard write operation
    setCopiedItemId(shuttleId); // Check if copiedItemId is correctly set
    setTimeout(() => {
      setCopiedItemId(shuttleId); // Reset copiedItemId after timeout
    }, 1);
  };

  return (
    <React.Fragment>
      {bookedDriver && <div className="backdrop"></div>}
      <div
        className="main-container"
        id="privatedriver"
        style={props.toggle ? { padding: "15px 0px" } : {}}
      >
        <div
          className="driverlist"
          style={{
            pointerEvents: isLoadingRoute ? "none" : "auto",
            opacity: isLoadingRoute ? "0.7" : "1",
          }}
        >
          <div className="header">
            {/* <h4>Driver List</h4> */}
            {/* <p
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "300",
              }}
            >
              Driver List 
            </p> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "25px",
              }}
            >
              {!isSearchActive && (
                <>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "300",
                      // margin: "0",
                    }}
                  >
                    Driver List
                  </p>
                  <div
                    onClick={handleSearchClick}
                    style={{ cursor: "pointer" }}
                  >
                    <CiSearch style={{ fontSize: "20px" }} />
                    {/* <FaSearch /> */}
                  </div>
                </>
              )}
              {isSearchActive && (
                <div style={{ display: "flex", gap: "42px" }}>
                  <div>
                    <TextField
                      type="text"
                      ref={searchInputRef}
                      value={searchQuery}
                      autoFocus
                      onChange={handleSearchChange}
                      placeholder="Search Drivers"
                      style={{ fontSize: "10px", width: "120%" }}
                      InputProps={{
                        startAdornment: (
                          <CiSearch
                            style={{
                              fontSize: "18px",
                              marginRight: "8px",
                              color: "#999",
                            }}
                          />
                        ),
                      }}
                      variant="standard"
                    />
                  </div>
                  <div
                    onClick={clearSearch}
                    style={{ cursor: "pointer", alignContent: "center" }}
                  >
                    <FaTimes style={{ color: "#D83434", fontSize: "16px" }} />
                  </div>
                </div>
              )}
            </div>

            <div className="driver-filter">
              <Tabs
                variant="fullWidth"
                sx={{
                  button: { padding: "0", fontSize: "12px" },
                  div: { minHeight: "35px" },
                  span: { bottom: "5px" },
                }}
                style={{ cursor: "pointer" }}
                centered
                value={servicesTabbarValue}
                onChange={(e, newValue) => {
                  if (newValue == "0")
                    // setFilteredDriverData(props.driverData.filter(data => data.driverName.toLowerCase().includes(searchInputRef?.current?.value?.toLowerCase())));
                    setFilteredDriverData(props.driverData);
                  else if (newValue == "2")
                    setFilteredDriverData(
                      props.driverData.filter((data) => data?.isOnTrip == "1")
                    );
                  else if (newValue == "1")
                    setFilteredDriverData(
                      props.driverData.filter(
                        (data) => data?.isOnline == "1" && data?.isOnTrip == "0"
                      )
                    );
                  setDriverFilterType(
                    newValue == "0"
                      ? "All"
                      : newValue == "1"
                      ? "Online"
                      : "On Trip"
                  );
                  setOnTripDriverEmail("");
                  setServicesTabbarValue(newValue);
                }}
                aria-label="basic tabs example"
              >
                <Tab
                  label="All"
                  style={{ fontWeight: "bold" }}
                  sx={{ button: { minHeight: "35px" } }}
                />
                <Tab
                  label="Online"
                  style={{ fontWeight: "bold" }}
                  sx={{ button: { minHeight: "35px" } }}
                />
                <Tab
                  label="On Trip"
                  style={{ fontWeight: "bold" }}
                  sx={{ button: { minHeight: "35px" } }}
                />
              </Tabs>
            </div>
          </div>
          <div className="driverDetails">
            {props.isLoading && (
              <CircularProgress
                sx={{
                  position: "absolute",
                  top: "40%",
                  left: "45%",
                  transform: "translate(-50%,-50%)",
                }}
              />
            )}
            {filteredDriverData?.length < 1 && !props.isLoading && (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                No Drivers Available
              </div>
            )}
            {filteredDriverData?.map((ele) => {
              return (
                <div
                  id={ele.driverEmail}
                  key={ele.driverEmail}
                  className={`driverSingleData ${
                    ele.isOnTrip == "1" ? "driverContainer" : ""
                  } ${
                    onTripDriverEmail === ele.driverEmail ? "currentDriver" : ""
                  }`}
                  // onClick={() =>
                  //   onTripDriverClickHandler(
                  //     ele?.driverEmail,
                  //     ele?.isOnTrip,
                  //     ele?.driverName,
                  //     ele?.tripId
                  //   )
                  // }
                  // onClick={() => {
                  //   // setTripIdOfOnTrip(ele?.tripId); // Always set tripId on div click

                  //     // Handle other click events (if any)
                  //     onTripDriverClickHandler(
                  //       ele?.driverEmail,
                  //       ele?.isOnTrip,
                  //       ele?.driverName,
                  //       ele?.tripId
                  //     );
                  //   // Only call bookButtonClickHandler if driver is not on a trip

                  //   if (ele?.isOnTrip != "1"){
                  //     bookButtonClickHandler(
                  //       ele?.driverImage,
                  //       ele?.driverName,
                  //       ele?.carNumber,
                  //       ele?.vehicleType,
                  //       ele?.driverEmail,
                  //       ele?.carModel,
                  //       ele?.carColor,
                  //       ele?.activeShiftCororateName,
                  //       ele?.activeShiftCorporateId
                  //     );
                  //   }

                  // }}
                  onClick={() => {
                    // Always set tripId on div click
                    setTripIdOfOnTrip(ele?.tripId);

                    // Handle on-trip driver click events
                    onTripDriverClickHandler(
                      ele?.driverEmail,
                      ele?.isOnTrip,
                      ele?.driverName,
                      ele?.tripId
                    );

                    // Trigger bookButtonClickHandler only if the driver is either on a trip or both online and shift started
                    if (
                      ele?.isOnTrip != "1" &&
                      ele?.isShiftStarted == "1" &&
                      ele?.isOnline == "1"
                    ) {
                      bookButtonClickHandler(
                        ele?.driverImage,
                        ele?.driverName,
                        ele?.carNumber,
                        ele?.vehicleType,
                        ele?.driverEmail,
                        ele?.carModel,
                        ele?.carColor,
                        ele?.activeShiftCororateName,
                        ele?.activeShiftCorporateId,
                        ele?.tripId
                      );
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ position: "relative", alignSelf: "center" }}>
                      <img className="driverPhoto" src={ele.driverImage} />
                      <p
                        className={
                          ele?.isOnTrip == "1"
                            ? "ontrip"
                            : ele.isOnline == "1"
                            ? "online"
                            : "offline"
                          // : "offline"
                        }
                      ></p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span className="drivername">{ele.driverName}</span>
                      <span className="carnumber">
                        {ele.carNumber.replaceAll("-", "")}
                      </span>
                      {(ele.isOnTrip == "1" ||
                        (ele.isOnline == "1" && ele.isShiftStarted == "1")) && (
                        <span
                          style={{
                            fontSize: "10px",
                            fontFamily: "Poppins",
                            textTransform: "capitalize",
                            color: "gray",
                          }}
                        >
                          {ele.isOnTrip == "0" ? "private" : ele?.tripType}
                        </span>
                      )}
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      ></div>
                    </div>
                  </div>
                  {(ele?.isOnTrip == "1" ||
                    (ele?.isOnline == "1" && ele?.isShiftStarted == "1")) && (
                    // <button
                    //   className={
                    //     ele?.isOnTrip == "1" ? "onTripButtton" : "bookButton"
                    //   }
                    //   onClick={
                    //     ele?.isOnTrip != "1"
                    //       ? () =>
                    //           bookButtonClickHandler(
                    //             ele?.driverImage,
                    //             ele?.driverName,
                    //             ele?.carNumber,
                    //             ele?.vehicleType,
                    //             ele?.driverEmail,
                    //             ele?.carModel,
                    //             ele?.carColor,
                    //             ele?.activeShiftCororateName,
                    //             ele?.activeShiftCorporateId,
                    //             ele?.tripId
                    //           )

                    //       : null
                    //   }
                    // >
                    //   {ele?.isOnTrip == "1" ? "On Trip" : "Book"}
                    // </button>
                    <button
                      className={
                        ele?.isOnTrip == "1" ? "onTripButtton" : "bookButton"
                      }
                      // onClick={() => {
                      //   setTripIdOfOnTrip(ele?.tripId); // Always set tripId on button click
                      //   if (ele?.isOnTrip != "1") {
                      //     bookButtonClickHandler(
                      //       ele?.driverImage,
                      //       ele?.driverName,
                      //       ele?.carNumber,
                      //       ele?.vehicleType,
                      //       ele?.driverEmail,
                      //       ele?.carModel,
                      //       ele?.carColor,
                      //       ele?.activeShiftCororateName,
                      //       ele?.activeShiftCorporateId
                      //     );
                      //   }
                      // }}
                    >
                      {ele?.isOnTrip == "1" ? "On Trip" : "Book"}
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="privateDriverMap-container">
          <div className="mapText">
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "300",
              }}
            >
              Live Trip Tracker
            </span>
          </div>
          <div className="livetrip" id="live-map"></div>

          {riderDetails?.length > 0 && (
            <div
              className="riderInfoContainer"
              style={{
                border: "1px solid #ccc",
                padding: "0px 8px",
                borderRadius: "8px",
                width: "auto",
                height: "auto",
                // background:'transparent'
                opacity: "0.9",
              }}
            >
              {/* New Code */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // marginBottom: "8px",
                  // marginTop: "4px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    // gap: "10px",
                    alignItems: "center",
                    // marginBottom: "5px",
                  }}
                >
                  <Tooltip
                    title={copiedItemId === trpID ? "Copied!" : "Copy Route ID"}
                    arrow
                  >
                    <IconButton
                      style={{ marginLeft: "-5%" }}
                      onClick={(event) => handleCopyClick(event, trpID)}
                    >
                      <FileCopyIcon style={{ width: "14px" }} />
                    </IconButton>
                  </Tooltip>
                  <div>
                    <div> {trpID.substring(0, 8) + "..."}</div>
                  </div>
                </div>
              </div>

              {/* New Code with the main Driver Name */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // marginBottom: "8px",
                  // marginTop: "4px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <FaRegUserCircle />
                  <div>
                    <div>{firstRider.RiderName}</div>
                    <div style={{ fontSize: "11px", alignItems: "center" }}>
                      {firstRider.MobileNumber}
                    </div>
                  </div>
                  {restRiders.length > 1 && (
                    <button
                      onClick={toggleExpand}
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                    >
                      {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </button>
                  )}
                </div>
              </div>

              {isExpanded && restRiders.length > 0 && (
                <div
                  className="additionalRiders"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  {restRiders?.map((rider, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "8px",
                        marginTop: "4px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        <FaRegUserCircle />
                        <div>
                          <div>{rider.RiderName}</div>
                          <div
                            style={{ fontSize: "11px", alignItems: "center" }}
                          >
                            {rider.MobileNumber}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {isLoadingRoute && (
            <div
              style={{
                position: "absolute",
                top: "40px",
                left: "0",
                width: "100%",
                height: "calc(100% - 40px)",
                backgroundColor: "white",
                opacity: "0.5",
              }}
            ></div>
          )}
          {isLoadingRoute && <Loading driver="true" />}
        </div>
      </div>
      {bookedDriver && (
        <DriverBooking
          bookedDriver={bookedDriver}
          setBookedDriver={setBookedDriver}
          riderData={props.riderData}
          tripRequestStatusFunc={(status) => {
            setTripRequestStatus(status);
            setBookedDriver(false);
          }}
        />
      )}
      {isTripEnded && (
        <Message
          type="success"
          message={onTripDriverName + "'s Trip has been ended"}
        />
      )}
    </React.Fragment>
  );
};

export default LiveTracking;
